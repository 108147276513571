<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-dark">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-dark width-available text-center">
        <strong>
          Ponyoka Deposit
        </strong>
      </div>
    </section>
    <div class="px-2">
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>How to Deposit</strong>
        </div>
        <div class="promo-content text-dark collapse p-2 show" id="1">
          <!--Some html static content-->
          <p>To start betting, you need to put money in your account using Mobile Money. Simply use the below option offered.
          </p>
          <!-- <p>1. Online deposit: <br>
            Enter the amount you want to deposit.<br>
            Click "Pay *", then enter your service pin into your phone to authorize the transaction.
          </p> -->
          <p>1. Paybill Option<br>
            Go to Mpesa menu<br>
            Select Payment services<br>
            Click on Paybill<br>
            Enter business number as 222563<br>
            Enter the account number as Ponyoka<br>
            Enter the amount you want to transfer to Ponyoka account<br>
            Enter your Mpesa pin and Confirm the request<br>
            You will shortly receive an SMS from Mpesa to confirm the transaction.
          </p>
          <p>
            DISCLAIMER:<br>
            Ponyoka accepts no responsibility should you make a deposit into any account other than that of the Company,
            or enter your own account details incorrectly when requesting a withdrawal. Your deposit is credited to the
            Ponyoka username account matching the Mpesa mobile number used for making a deposit. It is your
            responsibility to ensure that you add your correct customer data as indicated on our site instructions and
            the correct Ponyoka account or payment details. In the event that an error occurs, Ponyoka accepts no
            responsibility for recovering these funds and your account will NOT be credited.
          </p>
        </div>

        <div class="modal fade" id="initMpesaDeposit" tabindex="-1" role="dialog"
          aria-labelledby="initMpesaDepositLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div style="background-color: var(--grey);" class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="initMpesaDepositLabel">DEPOSIT</h5>
              </div>
              <div class="modal-body">
                <div class="text-center blue-txt" v-text="message"></div>
                <div class="form">

                  <div class="form-group" style="margin-bottom: -1em;">

                    <div style="display: flex;justify-content: space-around;">

                      <div style="margin: 3px;border-radius: 5px;" class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(33)">
                        <span class="bold">+</span> 33
                      </div>

                      <div style="margin: 3px;border-radius: 5px;" class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(99)">
                        <span class="bold">+</span> 99
                      </div>

                      <div style="margin: 3px;border-radius: 5px;" class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(298)">
                        <span class="bold">+</span> 298
                      </div>

                      <div style="margin: 3px;border-radius: 5px;" class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(499)">
                        <span class="bold">+</span> 499
                      </div>

                      <div style="margin: 3px;border-radius: 5px;" class="btn btn-primary btn-sm depo-btn pr-3 pl-3 white-txt" @click="setAmount(1999)">
                        <span class="bold">+</span> 1999
                      </div>

                    </div>

                    <input style="width: 100%;height: 40px;text-align:center;background-color: black;" placeholder="Enter amount to deposit" type="number" v-model="amount" class="w-100 mt-3 mb-3 small-txt" id="amount"
                      aria-describedby="amountHelp">
                  </div>

                  <br/>

                  <!--<div  style="font-size: var(&#45;&#45;font-small); text-align: left;">Minimum Kes 5</div>-->

                  <button class="btn btn-block btn-success btn-sm bg-blue" @click="deposit" v-bind:class="loading">Deposit</button>

                </div>
                <div class="text-center">
                  <!-- <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                    class="btn ww-100 mb-2"
                    :to="{ name: 'initMpesaDeposit', params: { IsDemo: 0, providerID: o.provider_id, gameName: o.game_name, gameID: o.game_id, launchURL: o.launch_url, launchType: o.launch_type, launchParameters: o.launch_parameters }, }">
                    Play Now
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


    </div>

    <!-- <section class="social-links" style=" ">
       <div class="text-dark width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>
// import jQuery from "jquery";
// const $ = jQuery;


import mqtt from "mqtt";
import wallet from "@/services/wallet";

// const SoccerIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Soccer.vue');
// const HomeIcon = () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Home.vue');

export default {
  name: 'app',
  components: {
    // SoccerIcon,
    // HomeIcon,
  },
  mounted: function () {

    var vm = this;

    vm.myProfile = vm.getProfile();

    this.initMenuModal();

    this.initDepositModal();

    this.getSports();

    vm.autoRefreshUI(vm.$vnode.tag);

    vm.reloadProfile();

    this.EventBus.$on('deposit:popup',function(payload){
      vm.amount = payload.amount;
      vm.message = payload.message;
      document.getElementById("deposit-init").click();

    });

    this.EventBus.$on('init:mqtt',function(){

      console.log('Wants Inititalize MQTT');

      if(vm.mqttClient !== false ) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referrer = document.referrer
    console.log('GOT referrer '+referrer)

    if(utm_source.length > 0 ) {

      this.setValue('utm_source',utm_source)
    }

    if(utm_medium.length > 0 ) {

      this.setValue('utm_medium',utm_medium)
    }

    if(utm_campaign.length > 0 ) {

      this.setValue('utm_campaign',utm_campaign)
    }

    //if(referrer.length > 0 ) {

      this.setValue('referrer',referrer)

   // }

  },
  computed: {

    bonus: function (){

      return this.formatCurrency(this.$store.state.bonusBalance);

    },

    bal: function() {

      return this.formatCurrency(this.$store.state.walletBalance);

    },

    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }


      var fn = this.profile.first_name;
      var mn = this.profile.middle_name;
      var ln = this.profile.last_name;
      //var ms = this.profile.msisdn;
      return fn + ' ' + mn + ' ' + ln ;

    },

    profile: function() {

      return this.getProfile();

    },

    current_page: function () {

      return this.$store.state.current_page;

    },

    sports: function () {

      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },

    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },

    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },

    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if(!p) {

        return 0;

      }

      return p.b

    },

    betslip_count: function () {

      if(!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {

    livescore: function(){


    },

    isIframe: function (){

      return this.current_page === "casinogames"

    },

    initMqtt: function(){

      var vm = this;

      if(this.mqttClient !== false ) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      var client  = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST,options);

      client.on('connect', function () {

        if(vm.profile) {

          var profileTopic = 'topic/profile/'+vm.profile.t;
          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic '+profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.oddsUpdate(payload);
      })

      this.mqttClient = client;

    },

    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {

      this.dismiss();
      console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },

    goTo: function (Name) {

      this.dismiss();

      Name = Name.toLowerCase();

      if (this.$store.state.current_page === Name)
        return

      this.$router.push({name: Name});
    },
    initDeposit: function () {

      this.dismiss();
      this.$router.push({name: 'deposit'});
    },
    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },
    getSports: function () {

      this.$store.dispatch("getSports");

    },

    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },

    deposit: function () {

      var vm = this;
      this.reset();

      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({name: "login", params: {}});
        return;
      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;

      }

      var path = '/deposit/initiate';
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = "loading";

      wallet
          .post(path, JSON.stringify(data), {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {

            // console.log("Response", res);
            vm.loading = "";
            var msg = res.data.message.data;
            vm.setSuccess("Deposit Initiated", msg);
            vm.message = "Please enter amount you want to deposit";

          })
          .catch((err) => {

            console.log("Response", err);

            vm.loading = "";

            if (err.response) {

              if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();

              } else {

                vm.setError("Failed", err.response.data.message);

              }

            } else if (err.request) {

              // vm.setError(
              //     "Network Error",
              //     "Check your network connection and try again"
              // );
              // console.log(JSON.stringify(err.request));

            } else {

              // vm.setError(
              //     "Network Error",
              //     "Check your network connection and try again"
              // );
              // console.log(JSON.stringify(err));

            }
          });
    },


    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDepositModal: function(){

      // Get the modal
      var modal = document.getElementById("deposit-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("deposit-init");

      // Get the <span> element that closes the modal
      var span = document.getElementById("deposit-modal-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#deposit-modal")) {

          modal.style.display = "none";
        }

      });


    },

    showDeposit: function() {

      document.getElementById("deposit-init").click();

    },
    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    }
  },
  data: function () {
    return {
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message:'Please enter amount you want to deposit',
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>